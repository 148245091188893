import React from 'react'

function blogs() {
  return (
    <div>
        

		<div class="page-header">
			<div class="page-header__bg"
				style={{backgroundImage: `url('assets/images/background/page-header-bg-1-1.jpg')`}}></div>
		 
			<div class="container">
				<ul class="thm-breadcrumb list-unstyled">
					<li><a href="/">Home</a></li>
					<li>Blog</li>
				</ul>
				<h2 class="page-header__title">Blog Page</h2> 
			</div> 
		</div> 


		<section class="mt-3 section-padding--bottom">
			<div class="container">
				<p className="text-dark">
				Right Time Limited's blog section offers insigh�ul articles on a variety of topics related to
cybersecurity, IT management, and industry trends. These blogs provide readers with up-to-date
information, expert opinions, and practical tips to enhance their understanding of key issues and make
informed decisions.				</p>
				<div class="row gutter-y-30">
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="000ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-1.jpg" alt=""/>
 							</div>
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div>
									<a href="/blog-details" class="blog-card-one__category">Designer</a>
								
								</div>
								<h3 class="blog-card-one__title"><a href="/blog-details">Web design done Delightful
										Visualization Examples</a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="100ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-2.jpg" alt=""/>
 							</div>
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div>
									<a href="/blog-details" class="blog-card-one__category">Graphic</a>
								
								</div>
								<h3 class="blog-card-one__title"><a href="/blog-details">Technology Support Allows
										Erie non-profit to Serve</a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="200ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-3.jpg" alt=""/>
 							</div>
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div>
									<a href="/blog-details" class="blog-card-one__category">SEO</a>
								
								</div>
								<h3 class="blog-card-one__title"><a href="/blog-details">Software Makes Your Profit
										Double if You Scale Properly</a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a>
							</div>
						</div>
					</div>
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="300ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-4.jpg" alt=""/>
 							</div> 
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div> 
									<a href="/blog-details" class="blog-card-one__category">Designer</a>
 								</div> 
								<h3 class="blog-card-one__title"><a href="/blog-details">Bring to the table win-win
										survival strategies to </a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a> 
							</div> 
						</div> 
					</div> 
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="400ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-5.jpg" alt=""/>
 							</div> 
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div> 
									<a href="/blog-details" class="blog-card-one__category">Graphic</a>
								 
								</div> 
								<h3 class="blog-card-one__title"><a href="/blog-details">Capitalize on low hanging
										fruit to identify a ballpark </a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a> 
							</div> 
						</div> 
					</div> 
					<div class="col-lg-4 col-md-6 col-sm-12 wow fadeInUp" data-wow-duration="1500ms"
						data-wow-delay="500ms">
						<div class="blog-card-one">
							<div class="blog-card-one__image">
								<img src="assets/images/blog/blog-1-6.jpg" alt=""/>
 							</div> 
							<div class="blog-card-one__content">
								<div class="blog-card-one__meta">
									<div class="blog-card-one__date">
										<i class="fa fa-calendar-alt" aria-hidden="true"></i>
										July, 25, 2022
									</div> 
									<a href="/blog-details" class="blog-card-one__category">SEO</a>
 								</div> 
								<h3 class="blog-card-one__title"><a href="/blog-details">User generated content in
										real-time will have multiple </a></h3>
								<a href="/blog-details" class="blog-card-one__more">
									Read More
									<i class="fa fa-arrow-right"></i>
								</a> 
							</div> 
						</div> 
					</div> 
				</div> 
			</div> 
		</section><br/><br/>
    </div>
  )
}

export default blogs